<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-80 mx-auto">

        <form @submit.prevent="add">
      <md-card>
        <md-card-header md-alignment="space-between">
          <div class="md-layout">
            <div class="md-layout-item md-size-75" style="padding-left: 0 !important">
                <div style="display: grid; grid-template-columns: 60px auto;">
                  <div class="timeline-badge danger" style="grid-row: 1/3;">{{ prospectPrefix(cliente.nome) }}</div>
                  <div style="display: flex; margin: 12px; font-size: 1.6rem; cursor: pointer; grid-column: 2/3;">{{ cliente.nome }}
                    <div class="tags" style="margin-left: 15px; margin-top: -5px;">
                      <span
                        v-for="(tag, index) in cliente.tags"
                        :key="'tag' + index"
                        class="badge badge-default"
                        :style="{ backgroundColor: tag.color, margin: '.1rem' }"
                      >
                        {{ tag.name }}
                      </span>
                    </div>
                  </div>
                  <div style="opacity: 0.84" class="md-subhead">
                    <div v-if="cliente.telefone"><md-icon>phone</md-icon>{{ cliente.telefone }}</div>
                    <div v-if="cliente.email"><md-icon>email</md-icon>{{ cliente.email }}</div>
                  </div>
                </div>
            </div>
            <div class="md-layout-item md-size-25 ml-auto">
              <div>
                <div style="text-align: center;">
                  <div class="md-group">
                    <md-button class="md-round md-sm" :class="statusClass(3, cliente.status)" @click="setStatus(cliente, 3)"><md-icon>close</md-icon>
                      <md-tooltip md-direction="bottom">perdido</md-tooltip>
                    </md-button>
                    <md-button class="md-round md-sm" :class="statusClass(1, cliente.status)" @click="setStatus(cliente, 1)"><md-icon>directions_run</md-icon>
                      <md-tooltip md-direction="bottom">em andamento</md-tooltip>
                    </md-button>
                    <md-button class="md-round md-sm" :class="statusClass(2, cliente.status)" @click="setStatus(cliente, 2)"><md-icon>emoji_events</md-icon>
                      <md-tooltip md-direction="bottom">ganho</md-tooltip>
                    </md-button>
                  </div>
                </div>
                <div style="padding-top: 15px; text-align: center;">
                  Previsao de receita: <h3 style="margin-top: 0px; margin-bottom: 0px;">{{ cliente.receita | toCurrency }}</h3>
                </div>
              </div>
            </div>
            <div>
              <span><b>Data cadastro: </b>{{ createdDateFormat(cliente.createdAt) }}</span>
            </div>
            <div class="md-layout-item md-size-100 fv-card" style="padding-left: 0 !important;">
              <div>
                <div class="md-group">
                  <md-button class="md-round md-sm" :class="etapaClass(1, cliente.etapa)" @click="setEtapa(cliente, 1)"></md-button>
                  <md-button class="md-round md-sm" :class="etapaClass(2, cliente.etapa)" @click="setEtapa(cliente, 2)"></md-button>
                  <md-button class="md-round md-sm" :class="etapaClass(3, cliente.etapa)" @click="setEtapa(cliente, 3)"></md-button>
                  <md-button class="md-round md-sm" :class="etapaClass(4, cliente.etapa)" @click="setEtapa(cliente, 4)"></md-button>
                </div>
                <div>
                  <span><b>Etapa: </b>{{ etapaNome(cliente.etapa) }} (Funil de Vendas)</span>
                </div>
              </div>
            </div>
         </div>
        </md-card-header>

        <md-card-content>
          <h3>O que foi feito e qual o próximo passo?</h3>
          <div class="md-layout spacing">
            <label class="md-layout-item md-size-10 md-form-label">
              Tipo
            </label>
            <div class="md-layout-item">
              <md-radio v-model="atividade.tipo" :value="1">Nota</md-radio>
              <md-radio v-model="atividade.tipo" :value="2">Ligação</md-radio>
              <md-radio v-model="atividade.tipo" :value="3">Tarefa</md-radio>
              <md-radio v-model="atividade.tipo" :value="4">Proposta</md-radio>
            </div>
          </div>

          <div class="md-layout">
            <label class="md-layout-item md-size-10 md-form-label">
              Descreva aqui suas açoes
            </label>
            <div class="md-layout-item">
              <ckeditor
                :editor="editor"
                v-model="atividade.texto"
                :config="editorConfig"
              ></ckeditor>
              <validation-error :errors="apiValidationErrors.texto" />
            </div>
          </div>

          <div class="md-layout">
            <label class="md-layout-item md-size-10 md-form-label">
              Responsável
            </label>
            <div class="md-layout-item md-size-20">
              <md-field>
                <label>Selecione...</label>
                <md-select v-model="atividade.responsavel.id" name="responsaveis">
                  <md-option
                    v-for="responsavel in available_responsaveis"
                    :key="responsavel.id"
                    :value="responsavel.id"
                  >
                    {{ responsavel.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="md-layout">
            <label class="md-layout-item md-size-10 md-form-label">
              Prazo
            </label>
            <div class="md-layout-item md-size-20">
              <md-datepicker md-immediately
                v-model="atividade.agenda_at"
                :md-model-type="String"
                :md-open-on-focus="false"
                v-mask="'##/##/####'"
                :class="{ 'md-invalid': !!apiValidationErrors.agenda_at }"
              >
                <label>Selecione...</label>
                <validation-error :errors="apiValidationErrors.agenda_at" />
              </md-datepicker>
            </div>
          </div>

          <div>
            <md-button @click="goBack" class="md-primary">
              <md-icon>arrow_back</md-icon>
              Voltar
            </md-button>
            <md-button type="submit" class="md-success">
              <md-icon>save</md-icon>
              Adicionar
            </md-button>
          </div>
        </md-card-actions>
      </md-card>
        </form>
    </div>

    <div class="md-layout-item md-size-80 mx-auto">
      <md-card v-for="item in table" :key="item.id">
        <md-card-header md-alignment="space-between">
          <div class="md-layout">
            <div class="md-layout-item md-size-30" style="padding-left: 0 !important">
              <badge :type="badgeType(item.tipo)">{{ badgeName(item.tipo) }}</badge>
              <div class="md-subhead" style="font-size: 12px;">{{ registerDateFormat(item.createdAt) }}</div>
            </div>
            <div class="md-layout-item ml-auto" style="text-align: right">
              <md-icon>support_agent</md-icon>
              {{ item.responsavel.name }}
            </div> 
          </div>
        </md-card-header>

        <md-card-content>
          <span v-html="item.texto"></span>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div>
            <div v-if="item.agenda_at">
              <md-icon>event</md-icon>
              {{ taskDateFormat(item.agenda_at) }}
              <span v-if="item.status == 0 && item.atrasado" style="margin-left: 15px"><md-icon style="color: red">warning_amber</md-icon></span>
            </div>
          </div>
          <div>
            <md-button class="md-simple md-warning" @click="setAtividadeStatus(item, 0)" v-if="item.status">
              <md-icon>clear</md-icon>Não Finalizada
            </md-button>
            <md-button class="md-simple md-primary" @click="setAtividadeStatus(item, 1)" v-if="!item.status">
              <md-icon>done</md-icon>Finalizar
            </md-button>
            <md-button class="md-simple md-success" @click="goToEdit(item.id)">
              <md-icon>edit</md-icon>Editar
            </md-button>
            <md-button class="md-simple md-danger" @click="destroy(item.id)">
              <md-icon>delete</md-icon>Excluir
            </md-button>
          </div>
        </md-card-actions>
      </md-card>
      <md-card>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Exibindo de {{ from + 1 }} - {{ to }} de {{ total }} atividade(s)
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Pagination from "@/components/Pagination";
import Swal from "sweetalert2";
import { Badge } from "@/components";
import moment from "moment";
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import NProgress from 'nprogress';
import Vue from 'vue';
import Vuex from 'vuex';
import { MdField } from 'vue-material/dist/components'

Vue.use(MdField);

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
  methods: {
    isInvalidValue: function isInvalidValue () {
      return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput;
    }
  }
}));

export default {
  components: {
    pagination: Pagination,
    Badge,
    ValidationError,
    ckeditor: CKEditor.component
  },

  mixins: [formMixin],

  data() {
    return {
      responsive: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "Outdent",
          "Indent",
          "TextColor",
          "BGColor",
        ],
      },
      atividade: {
        type: 'atividades',
        tipo: 0,
        texto: null,
        agenda_at: null,
        status: 0,
        responsavel: {
          id: null,
          type: "users",
        },
        prospect: {
          id: this.$route.params.id,
          type: "prospects",
        },
        relationshipNames: ["responsavel", "prospect"]
      },
      available_responsaveis: [],
      cliente: {},
      table: [],

      query: null,

      sortation: {
        field: "created_at",
        order: "desc",
      },

      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200],
      },

      total: 0,
    };
  },

  computed: {
    ...Vuex.mapState({
      me: state => state.profile.me
    }),

    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    simpleTimeline() {
      if (this.responsive) {
        return "simple";
      }
      return "";
    }
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    sortation: {
      handler: "getList",
      immediate: false,
      deep: true,
    },

    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  filters: {
    toCurrency(value) {
      if (typeof value !== "number") {
          return value;
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
      });
      return formatter.format(value);
    }
  },

  created() {
    this.getResponsaveis();
    this.get();
  },

  methods: {
    async get() {
      NProgress.start();
      NProgress.set(0.4);
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("prospects/get", id);
        this.cliente = await this.$store.getters["prospects/prospect"];
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          "Oops, something went wrong!"
        );
      }
      NProgress.done();
    },

    async add() {
      this.resetApiValidation();

      this.atividade.agenda_at = this.atividade.agenda_at && moment(this.atividade.agenda_at, "DD/MM/YYYY").isValid() ? moment(this.atividade.agenda_at, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
      try {
        await this.$store.dispatch("atividades/add", this.atividade);
        await this.$store.dispatch("alerts/success","Atividade added successfully.");

        this.atividade = {
          type: 'atividades',
          tipo: 0,
          texto: '',
          agenda_at: null,
          status: 0,
          responsavel: {
            id: null,
            type: "users",
          },
          prospect: {
            id: this.$route.params.id,
            type: "prospects",
          },
          relationshipNames: ["responsavel", "prospect"]
        };

        if (this.me)
          this.atividade.responsavel.id = this.me.id;
        else
          this.atividade.responsavel.id = this.available_responsaveis[0].id;

        this.getList();
      } catch (e) {
        if(e.response.data.errors[0]) {
          await this.$store.dispatch("alerts/error", e.response.data.errors[0].title)
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch("alerts/error", "Oops, something went wrong!")
        }
      }
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    getList() {
      NProgress.start();
      NProgress.set(0.4);
      let params = {
        include: "user,responsavel,prospect",
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { name: this.query } : {}),
          ...(this.query ? { user: this.query } : {}),
          ...(this.query ? { prospect: this.query } : {}),
          ...({ prospect_id: this.$route.params.id }),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      this.$store.dispatch("atividades/list", params).then(() => {
        this.table = this.$store.getters["atividades/list"];
        this.total = this.$store.getters["atividades/listTotal"];
        NProgress.done();
      });
    },

    getResponsaveis() {
      this.$store.dispatch("users/list").then(() => {
        this.available_responsaveis = this.$store.getters["users/dropdown"];
        if (this.available_responsaveis && this.available_responsaveis.length) {
          if (this.me)
            this.atividade.responsavel.id = this.me.id;
          else
            this.atividade.responsavel.id = this.available_responsaveis[0].id;
        }
      });
    },

    async destroy(id) {
      const confirmation = await Swal.fire({
        title: "Excluir esta Atividade?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, remova-o!",
        cancelButtonText: "Não, mantenha-o",
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
      });

      if (confirmation.value === true) {
        try {
          await this.$store.dispatch("atividades/destroy", id);
          await this.$store.dispatch(
            "alerts/success",
            "Atividade deleted successfully."
          );
          await this.getList();
        } catch (e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              "alerts/error",
              e.response.data.errors[0].title
            );
          } else {
            await this.$store.dispatch(
              "alerts/error",
              "Oops, something went wrong!"
            );
          }
        }
      }
    },

    goToAdd() {
      this.$router.push({ name: "Add Atividade" });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Atividade", params: { id } });
    },

    goBack() {
      // this.$router.push({ name: "List Prospects" });
      this.$router.back();
    },

    customSort() {
      return false;
    },

    badgeName(tipo) {
      switch (tipo) {
        case 1:
          return 'Nota'
        case 2:
          return 'Ligacao'
        case 3:
          return 'Tarefa'
        case 4:
          return 'Proposta'
        default:
          return 'Nota'
      }
    },

    badgeType(tipo) {
      switch (tipo) {
        case 1:
          return 'info'
        case 2:
          return 'success'
        case 3:
          return 'danger'
        case 4:
          return 'warning'
        default:
          return 'info'
      }
    },

    badgeIcon(tipo) {
      switch (tipo) {
        case 1:
          return 'description'
        case 2:
          return 'call'
        case 3:
          return 'event'
        case 4:
          return 'request_page'
        default:
          return 'description'
      }
    },

    prospectPrefix(name) {
      if (name) {
        let words = name.split(" ");
        return words.map(word => word.substring(0, 1)).join("").substring(0, 2).toUpperCase();
      }
      return false;
    },

    statusClass(status, selected) {
      switch (status) {
        case 1:
          return selected == 1 ? 'md-warning' : ''
        case 2:
          return selected == 2 ? 'md-success' : ''
        case 3:
          return selected == 3 ? 'md-danger' : ''
        default:
          return ''
      }
    },

    etapaNome(etapa) {
      switch (etapa) {
        case 1:
          return 'Contato'
        case 2:
          return 'Envio de proposta'
        case 3:
          return 'Follow-up'
        case 4:
          return 'Fechamento'
        default:
          return ''
      }
    },

    etapaClass(etapa, selected) {
      switch (etapa) {
        case 1:
          return 'fv-on'
        case 2:
          return selected >= 2 ? 'fv-on' : 'fv-off'
        case 3:
          return selected >= 3 ? 'fv-on' : 'fv-off'
        case 4:
          return selected >= 4 ? 'fv-on' : 'fv-off'
        default:
          return ''
      }
    },

    async setEtapa(prospect, etapa) {
      try {
        prospect.etapa = etapa;
        await this.$store.dispatch("prospects/update", prospect);
        await this.$store.dispatch(
          "alerts/success",
          "Prospect updated successfully."
        );
        await this.getList();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
        } else {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, something went wrong!"
          );
        }
      }
    },

    async setStatus(prospect, status) {
      try {
        prospect.status = status;
        await this.$store.dispatch("prospects/update", prospect);
        await this.$store.dispatch(
          "alerts/success",
          "Prospect updated successfully."
        );
        await this.get();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
        } else {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, something went wrong!"
          );
        }
      }
    },

    async setAtividadeStatus(atividade, status) {
      try {
        atividade.status = status;
        await this.$store.dispatch("atividades/update", atividade);
        await this.$store.dispatch(
          "alerts/success",
          "Atividade updated successfully."
        );
        await this.getList();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
        } else {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, something went wrong!"
          );
        }
      }
    },

    createdDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('lll') : '';
    },

    taskDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('LL') : '';
    },

    registerDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('llll') : '';
    },

    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  },

  mounted() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  }
};
</script>
<style lang="scss">
.badge {
  font-size: 15px;
}
.spacing {
  padding-bottom: 1rem;
}
.md-card .md-card-content span>p {
  margin: 0;
}
.fv-card {
  margin-top: 15px;
  padding-top: 10px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgb(238, 238, 238);
}
.fv-card .md-button+.md-button {
  margin-left: 1px;
  margin-right: 1px;
}
</style>
