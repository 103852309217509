<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-80 mx-auto">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>tune</md-icon>
          </div>
          <h4 class="title">Filtros</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <label class="md-layout-item md-size-5 md-form-label">
              Buscar:
            </label>
            <div class="md-layout-item md-size-55">
              <md-field md-clearable>
                <label>Por texto...</label>
                <md-input type="text" v-model="filter.query"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-datepicker md-immediately
                v-model="filter.createdAtFrom"
                :md-model-type="String"
                :md-open-on-focus="false"
                v-mask="'##/##/####'"
              >
                <label>Periodo De:</label>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-size-20">
              <md-datepicker md-immediately
                v-model="filter.createdAtTo"
                :md-model-type="String"
                :md-open-on-focus="false"
                v-mask="'##/##/####'"
              >
                <label>Periodo Ate:</label>
              </md-datepicker>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-80 mx-auto">
      <div class="md-layout" style="margin-bottom: 50px;">
        <div class="md-layout-item">
          <div class="md-group">
            <md-button class="md-sm" :class="getFilterStatusClass(-1)" @click="setFilterStatus(-1)">Todas</md-button>
            <md-button class="md-sm" :class="getFilterStatusClass(0)" @click="setFilterStatus(0)">Pendentes</md-button>
            <md-button class="md-sm" :class="getFilterStatusClass(1)" @click="setFilterStatus(1)">Finalizadas</md-button>
          </div>
        </div>
        <div class="md-layout-item" style="margin-top: -15px;">
          <md-field>
            <label>Responsável</label>
            <md-select v-model="filter.responsavel.id" name="responsaveis">
              <md-option value="0">Todos</md-option>
              <md-option
                v-for="responsavel in available_responsaveis"
                :key="responsavel.id"
                :value="responsavel.id"
              >
                {{ responsavel.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item">
          <div class="text-right">
            <md-button class="md-primary md-sm" style="margin-right: 15px" @click="filterMyTasks()">
              <md-icon>checklist</md-icon>
              <span v-if="!filter.toggleMyTasks">Minhas Tarefas</span>
              <span v-if="filter.toggleMyTasks">Limpar Filtros</span>
            </md-button>
            <md-button class="md-danger md-sm" @click="goToAdd()">
              <md-icon>add_circle</md-icon>Nova Tarefa
            </md-button>
          </div>
        </div> 
      </div>
 
      <time-line plain>
        <time-line-item inverted :badge-type="badgeType(item.tipo)" :badge-icon="badgeIcon(item.tipo)" v-for="item in table" :key="item.id">
          <div slot="header">
            <div class="md-layout">
              <div class="md-layout-item md-size-30" style="padding-left: 0 !important">
                <badge :type="badgeType(item.tipo)"><span :class="taskDone(item.status)">{{ badgeName(item.tipo) }}</span></badge>
                <div class="md-subhead" style="font-size: 12px;">{{ registerDateFormat(item.createdAt) }}</div>
              </div>
              <div class="md-layout-item md-size-50 ml-auto" style="text-align: right">
                <div style="text-transform: uppercase;">
                  <md-icon>person</md-icon>
                  <a href="#" style="color: black;" @click="goToProspectsAtividades(item.prospect.id)">{{ item.prospect.nome }}</a>
                  <div style="text-align: right; text-transform: capitalize; opacity: 0.54;">
                    <md-icon>support_agent</md-icon>
                    {{ item.responsavel.name }}
                  </div> 
                </div>
              </div> 
            </div>
          </div>
          <p slot="content">
            <span v-html="item.texto"></span>
          </p>
          <div slot="footer">
            <div class="md-layout">
              <div v-if="item.agenda_at" class="md-layout-item md-size-30" style="padding-left: 0 !important; display: flex; align-items: center;">
                <md-icon style="margin: 5px;">event</md-icon>
                <span :class="taskDone(item.status)">{{ taskDateFormat(item.agenda_at) }}</span>
                <span v-if="item.status == 0 && item.atrasado" style="margin-left: 15px"><md-icon style="color: red">warning_amber</md-icon></span>
              </div>
              <div class="md-layout-item ml-auto" style="text-align: right">
                <md-button class="md-simple md-warning" @click="setStatus(item, 0)" v-if="item.status">
                  <md-icon>clear</md-icon>Não Finalizada
                </md-button>
                <md-button class="md-simple md-primary" @click="setStatus(item, 1)" v-if="!item.status">
                  <md-icon>done</md-icon>Finalizar
                </md-button>
                <md-button class="md-simple md-success" @click="goToEdit(item.id)">
                  <md-icon>edit</md-icon>Editar
                </md-button>
                <md-button class="md-simple md-danger" @click="destroy(item.id)">
                  <md-icon>delete</md-icon>Excluir
                </md-button>
              </div>
            </div>
          </div>
        </time-line-item>
      </time-line>
      <md-card>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Exibindo de {{ from + 1 }} - {{ to }} de {{ total }} atividade(s)
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Pagination from "@/components/Pagination";
import Swal from "sweetalert2";
import { TimeLine, TimeLineItem, Badge } from "@/components";
import moment from "moment";
import NProgress from 'nprogress';
import Vue from 'vue';
import Vuex from 'vuex';
import { MdField } from 'vue-material/dist/components';

Vue.use(MdField);

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
  methods: {
    isInvalidValue: function isInvalidValue () {
      return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput;
    }
  }
}));

export default {
  components: {
    pagination: Pagination,
    TimeLine,
    TimeLineItem,
    Badge
  },

  data() {
    return {
      responsive: false,
      table: [],

      sortation: {
        field: "created_at",
        order: "desc",
      },

      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200],
      },

      total: 0,

      filter: {
        query: null,
        createdAtTo: null,
        createdAtFrom: null,
        status: -1,
        toggleMyTasks: false,
        responsavel: {
          id: 0,
        },
      },
      available_responsaveis: [],
    };
  },

  computed: {
    ...Vuex.mapState({
      me: state => state.profile.me
    }),

    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    simpleTimeline() {
      if (this.responsive) {
        return "simple";
      }
      return "";
    }
  },

  watch: {
    filter: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },

    sortation: {
      handler: "getList",
      immediate: false,
      deep: true,
    },

    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    getList() {
      NProgress.start();
      NProgress.set(0.4);
      let createdAtBetween = new Array();
      if (this.filter.createdAtFrom)
        createdAtBetween.push(moment(this.filter.createdAtFrom, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00"));
      if (this.filter.createdAtTo)
        createdAtBetween.push(moment(this.filter.createdAtTo, "DD/MM/YYYY").format("YYYY-MM-DD 23:59:59"));

      let params = {
        include: "user,responsavel,prospect",
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.filter.query ? { texto: this.filter.query } : {}),
          ...(parseInt(this.filter.status) != -1 ? { status: this.filter.status, tarefas: 1 } : {}),
          ...(parseInt(this.filter.responsavel.id) ? { responsavel_id: this.filter.responsavel.id } : {}),
          ...(createdAtBetween.length == 2 ? { created_at: createdAtBetween } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      this.$store.dispatch("atividades/list", params).then(() => {
        this.table = this.$store.getters["atividades/list"];
        this.total = this.$store.getters["atividades/listTotal"];
        NProgress.done();
      });
    },

    async setStatus(atividade, status) {
      try {
        atividade.status = status;
        await this.$store.dispatch("atividades/update", atividade);
        await this.$store.dispatch(
          "alerts/success",
          "Atividade updated successfully."
        );
        await this.getList();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
        } else {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, something went wrong!"
          );
        }
      }
    },

    async destroy(id) {
      const confirmation = await Swal.fire({
        title: "Excluir esta Atividade?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, remova-o!",
        cancelButtonText: "Não, mantenha-o",
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
      });

      if (confirmation.value === true) {
        try {
          await this.$store.dispatch("atividades/destroy", id);
          await this.$store.dispatch(
            "alerts/success",
            "Atividade deleted successfully."
          );
          await this.getList();
        } catch (e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              "alerts/error",
              e.response.data.errors[0].title
            );
          } else {
            await this.$store.dispatch(
              "alerts/error",
              "Oops, something went wrong!"
            );
          }
        }
      }
    },

    getResponsaveis() {
      this.$store.dispatch("users/list").then(() => {
        this.available_responsaveis = this.$store.getters["users/dropdown"];
      });
    },

    goToAdd() {
      this.$router.push({ name: "Add Atividade" });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Atividade", params: { id } });
    },

    goToProspectsAtividades(id) {
      this.$router.push({ name: "List ProspectsAtividades", params: { id } });
    },

    filterMyTasks() {
      if (this.filter.toggleMyTasks) {
        this.filter.toggleMyTasks = false;
        this.filter.responsavel.id = 0;
        this.filter.status = -1;
      } else {
        this.filter.toggleMyTasks = true;
        this.filter.responsavel.id = this.me.id;
        this.filter.status = 0;
      }
    },

    customSort() {
      return false;
    },

    badgeName(tipo) {
      switch (tipo) {
        case 1:
          return 'Nota'
        case 2:
          return 'Ligacao'
        case 3:
          return 'Tarefa'
        case 4:
          return 'Proposta'
        default:
          return 'Nota'
      }
    },

    badgeType(tipo) {
      switch (tipo) {
        case 1:
          return 'info'
        case 2:
          return 'success'
        case 3:
          return 'danger'
        case 4:
          return 'warning'
        default:
          return 'info'
      }
    },

    badgeIcon(tipo) {
      switch (tipo) {
        case 1:
          return 'description'
        case 2:
          return 'call'
        case 3:
          return 'event'
        case 4:
          return 'request_page'
        default:
          return 'description'
      }
    },

    taskDone(status) {
      switch (status) {
        case 0:
          return ''
        case 1:
          return 'done'
        default:
          return ''
      }
    },

    getFilterStatusClass(status) {
      if (this.filter.status == status) {
        return "md-info";
      }
      return "";
    },

    setFilterStatus(status) {
      this.filter.status = status;
    },

    taskDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('LL') : '';
    },

    registerDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('llll') : '';
    },

    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  },

  created() {
    this.getResponsaveis();
    if (localStorage.atividadesFilters) {
      this.filter = JSON.parse(localStorage.atividadesFilters);
    }
  },

  mounted() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },

  beforeDestroy() {
    localStorage.atividadesFilters = JSON.stringify(this.filter);
    window.removeEventListener("resize", this.onResponsiveInverted);
  }
};
</script>
<style lang="scss">
.timeline {
  margin-top: -20px;
}
.timeline:before {
  left: 6%;
}
.timeline > li > .timeline-badge {
  left: 6%;
}
.timeline > li > .timeline-panel {
  width: 88%;
}
.badge {
  font-size: 15px;
}
.done {
  text-decoration: line-through;
}
</style>
