<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>tune</md-icon>
          </div>
          <h4 class="title">Filtros</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Responsável:
            </label>
            <div class="md-layout-item md-size-45">
              <md-field>
                <label>Selecione...</label>
                <md-select
                  v-model="filter.responsaveis"
                  name="responsaveis"
                  multiple
                >
                  <md-option
                    v-for="responsavel in available_responsaveis"
                    :key="'responsavel_' + responsavel.id"
                    :value="responsavel.id"
                  >
                    {{ responsavel.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <label class="md-layout-item md-size-10 md-form-label">
              Período:
            </label>
            <div class="md-layout-item md-size-15">
              <md-datepicker md-immediately
                v-model="filter.createdAtFrom"
                :md-model-type="String"
                :md-open-on-focus="false"
                v-mask="'##/##/####'"
              >
                <label>De:</label>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-size-15">
              <md-datepicker md-immediately
                v-model="filter.createdAtTo"
                :md-model-type="String"
                :md-open-on-focus="false"
                v-mask="'##/##/####'"
              >
                <label>Até:</label>
              </md-datepicker>
            </div>
          </div>

          <div class="md-layout spacing">
            <label class="md-layout-item md-size-15 md-form-label">
              Assunto:
            </label>
            <div class="md-layout-item md-size-45">
              <md-field>
                <label>Selecione...</label>
                <md-select
                  v-model="filter.tags"
                  name="tags"
                  multiple
                >
                  <md-option
                    v-for="tag in available_tags"
                    :key="'tag_' + tag.id"
                    :value="tag.id"
                  >
                    {{ tag.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <label class="md-layout-item md-size-10 md-form-label">
              Status:
            </label>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>Selecione...</label>
                <md-select
                  v-model="filter.status"
                  name="status"
                >
                  <md-option
                    v-for="status in available_status"
                    :key="'status_' + status.id"
                    :value="status.id"
                  >
                    {{ status.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>group</md-icon>
          </div>
          <h4 class="title">Prospects</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="table"
            :md-sort.sync="sortation.field"
            :md-sort-order.sync="sortation.order"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label>Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <div class="text-right">
                <md-button class="md-primary" @click="goToExport()">
                  <md-icon>download</md-icon>Exportar
                </md-button>
              </div>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Nome" md-sort-by="nome">{{
                item.nome
              }}</md-table-cell>
              <md-table-cell md-label="E-mail" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Telefone" md-sort-by="telefone">{{
                item.telefone
              }}</md-table-cell>
              <md-table-cell md-label="WhatsApp" md-sort-by="whatsapp">{{
                item.whatsapp
              }}</md-table-cell>
              <md-table-cell md-label="Receita" md-sort-by="receita">{{
                item.receita | toCurrency
              }}</md-table-cell>
              <md-table-cell md-label="Etapa" md-sort-by="etapa">{{
                getEtapa(item.etapa)
              }}</md-table-cell>
              <md-table-cell md-label="Status" md-sort-by="status">{{
                getStatus(item.status)
              }}</md-table-cell>
              <md-table-cell md-label="Interesse" md-sort-by="interesse">
                <span v-html="item.interesse"></span>
              </md-table-cell>
              <md-table-cell md-label="Responsável" md-sort-by="responsavel_id">{{
                item.responsavel.name
              }}</md-table-cell>
              <md-table-cell md-label="Criado Em" md-sort-by="created_at">{{
                createdDateFormat(item.createdAt)
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Exibindo de {{ from + 1 }} - {{ to }} de {{ total }} prospect(s)
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Swal from "sweetalert2";
import _ from "lodash";
import qs from 'qs';
import axios from 'axios';
import moment from "moment";
import Vue from 'vue';
import { MdField } from 'vue-material/dist/components'

Vue.use(MdField);

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
  methods: {
    isInvalidValue: function isInvalidValue () {
      return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput;
    }
  }
}));

export default {
  components: {
    pagination: Pagination,
  },

  data: () => ({
    table: [],

    sortation: {
      field: "created_at",
      order: "desc",
    },

    pagination: {
      perPage: 25,
      currentPage: 1,
      perPageOptions: [25, 50, 100, 200],
    },

    total: 0,

    filter: {
      status: -1,
      createdAtTo: null,
      createdAtFrom: null,
      tags: [],
      responsaveis: []
    },
    available_tags: [],
    available_responsaveis: [],
    available_status: [
      { id: -1, name: 'Todos' },
      { id: 1, name: 'Em Andamento' },
      { id: 2, name: 'Ganho' },
      { id: 3, name: 'Perdido' }
    ]
  }),

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  filters: {
    toCurrency(value) {
      if (typeof value !== "number") {
          return value;
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
      });
      return formatter.format(value);
    }
  },

  watch: {
    filter: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    sortation: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      let createdAtBetween = new Array();
      if (this.filter.createdAtFrom)
        createdAtBetween.push(moment(this.filter.createdAtFrom, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00"));
      if (this.filter.createdAtTo)
        createdAtBetween.push(moment(this.filter.createdAtTo, "DD/MM/YYYY").format("YYYY-MM-DD 23:59:59"));

      let params = {
        include: "user,responsavel",
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(parseInt(this.filter.status) != -1 ? { status: this.filter.status } : {}),
          ...(this.filter.responsaveis.length > 0 ? { responsaveis: this.filter.responsaveis } : {}),
          ...(this.filter.tags.length > 0 ? { tags: this.filter.tags } : {}),
          ...(createdAtBetween.length == 2 ? { created_at: createdAtBetween } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      await this.$store.dispatch("reports/prospects", params);
      this.table = this.$store.getters["reports/listProspects"];
      this.total = this.$store.getters["reports/listProspectsTotal"];
    },

    getTags() {
      this.$store.dispatch("tags/list").then(() => {
        this.available_tags = this.$store.getters["tags/dropdown"];
      });
    },

    getResponsaveis() {
      this.$store.dispatch("users/list").then(() => {
        this.available_responsaveis = this.$store.getters["users/dropdown"];
      });
    },

    getEtapa(etapa) {
      switch (etapa) {
        case 1:
          return 'Contato'
        case 2:
          return 'Envio de proposta'
        case 3:
          return 'Follow-up'
        case 4:
          return 'Fechamento'
        default:
          return ''
      }
    },

    getStatus(status) {
      switch (status) {
        case 1:
          return 'Em Andamento'
        case 2:
          return 'Ganho'
        case 3:
          return 'Perdido'
        default:
          return ''
      }
    },

    goToExport() {
      const url = process.env.VUE_APP_API_BASE_URL;
      let createdAtBetween = new Array();
      if (this.filter.createdAtFrom)
        createdAtBetween.push(moment(this.filter.createdAtFrom, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00"));
      if (this.filter.createdAtTo)
        createdAtBetween.push(moment(this.filter.createdAtTo, "DD/MM/YYYY").format("YYYY-MM-DD 23:59:59"));

      let params = {
        include: "user,responsavel",
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(parseInt(this.filter.status) != -1 ? { status: this.filter.status } : {}),
          ...(this.filter.responsaveis.length > 0 ? { responsavel_id: this.filter.responsaveis } : {}),
          ...(this.filter.tags.length > 0 ? { tags: this.filter.tags } : {}),
          ...(createdAtBetween.length == 2 ? { created_at: createdAtBetween } : {}),
        },
      };

      const options = {
        params: params,
        paramsSerializer: function (params) {
          return qs.stringify(params, {encode: false});
        },
        responseType: 'blob'
      };

      return axios.get(`${url}/report/prospects`, options).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `prospects_${+ new Date()}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },

    createdDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('L') : '';
    },

    customSort() {
      return false;
    },
  },

  created() {
    this.getTags();
    this.getResponsaveis();
    if (localStorage.reportProspectsFilters) {
      this.filter = JSON.parse(localStorage.reportProspectsFilters);
    }
  },

  mounted() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
  },
};
</script>
