<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>category</md-icon>
          </div>
          <h4 class="title">Adicionar Atividade</h4>
        </md-card-header>

        <form @submit.prevent="add">
          <md-card-content>

            <div class="md-layout" v-if="false">
              <div class="md-layout-item md-size-100">
                <div class="md-group">
                  <md-button class="md-warning"><md-icon>description</md-icon><md-tooltip md-direction="bottom">Nota</md-tooltip></md-button>
                  <md-button><md-icon>event</md-icon><md-tooltip md-direction="bottom">Agenda</md-tooltip></md-button>
                  <md-button><md-icon>request_page</md-icon><md-tooltip md-direction="bottom">Proposta</md-tooltip></md-button>
                  <md-button><md-icon>call</md-icon><md-tooltip md-direction="bottom">Ligação</md-tooltip></md-button>
                </div>
              </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-10 md-form-label">
                Tipo
              </label>
              <div class="md-layout-item">
                <md-radio v-model="atividade.tipo" value="1">Nota</md-radio>
                <md-radio v-model="atividade.tipo" value="2">Ligação</md-radio>
                <md-radio v-model="atividade.tipo" value="3">Tarefa</md-radio>
                <md-radio v-model="atividade.tipo" value="4">Proposta</md-radio>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-10 md-form-label">
                Prospect
              </label>
              <div class="md-layout-item md-size-50">
                <md-autocomplete
                  class="search"
                  v-model="query"
                  :md-options="available_prospects"
                  @md-opened="searchProspect"
                  @md-changed="searchProspect"
                  @md-selected="selectProspect"
                  :md-open-on-focus="false"
                >
                  <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item.nome }}</template>
                  <validation-error :errors="apiValidationErrors.prospect" />
                </md-autocomplete>
              </div> 
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-10 md-form-label">
                Descrição
              </label>
              <div class="md-layout-item md-size-50">
                <ckeditor
                  :editor="editor"
                  v-model="atividade.texto"
                  :config="editorConfig"
                ></ckeditor>
                <validation-error :errors="apiValidationErrors.texto" />
              </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-10 md-form-label">
                Responsável
              </label>
              <div class="md-layout-item md-size-20">
                <md-field>
                  <label>Selecione...</label>
                  <md-select v-model="atividade.responsavel.id" name="responsaveis">
                    <md-option
                      v-for="responsavel in available_responsaveis"
                      :key="responsavel.id"
                      :value="responsavel.id"
                    >
                      {{ responsavel.name }}
                    </md-option>
                  </md-select>
                  <validation-error :errors="apiValidationErrors.responsavel" />
                </md-field>
              </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-10 md-form-label">
                Prazo
              </label>
              <div class="md-layout-item md-size-20">
                <md-datepicker md-immediately
                  v-model="atividade.agenda_at"
                  :md-model-type="String"
                  :md-open-on-focus="false"
                  v-mask="'##/##/####'"
                  :class="{ 'md-invalid': !!apiValidationErrors.agenda_at }"
                >
                  <label>Selecione...</label>
                  <validation-error :errors="apiValidationErrors.agenda_at" />
                </md-datepicker>
              </div>
            </div>
          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button @click="goBack" class="md-primary">
              <md-icon>arrow_back</md-icon>
              Voltar
            </md-button>
            <md-button type="submit" class="md-success">
              <md-icon>save</md-icon>
              Adicionar
            </md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Vue from 'vue';
import Vuex from 'vuex';
import { MdField } from 'vue-material/dist/components'

Vue.use(MdField);

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
  methods: {
    isInvalidValue: function isInvalidValue () {
      return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput;
    }
  }
}));

export default {
  components: { ValidationError, ckeditor: CKEditor.component },

  mixins: [formMixin],

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "Outdent",
          "Indent",
          "TextColor",
          "BGColor",
        ],
      },
      atividade: {
        type: 'atividades',
        tipo: '1',
        texto: null,
        agenda_at: null,
        status: 0,
        responsavel: {
          id: null,
          type: "users",
        },
        prospect: {
          id: null,
          type: "prospects",
        },
        relationshipNames: ["responsavel", "prospect"]
      },
      query: "",
      available_prospects: [],
      available_responsaveis: [],
    };
  },

  computed: {
    ...Vuex.mapState({
      me: state => state.profile.me
    })
  },

  created() {
    this.getResponsaveis();
  },

  methods: {
    async add() {
      this.resetApiValidation();

      this.atividade.agenda_at = this.atividade.agenda_at && moment(this.atividade.agenda_at, "DD/MM/YYYY").isValid() ? moment(this.atividade.agenda_at, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
      try {
        await this.$store.dispatch("atividades/add", this.atividade);
        await this.$store.dispatch("alerts/success","Atividade added successfully.");
        this.goBack();
      } catch (e) {
        if(e.response.data.errors[0]) {
          await this.$store.dispatch("alerts/error", e.response.data.errors[0].title)
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch("alerts/error", "Oops, something went wrong!")
        }
      }
    },

    async searchProspect() {
      try {
        if (!this.query || this.query == "" || this.query.length < 3)
          return;

        let params = {
          include: "responsavel",
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
        };

        await this.$store.dispatch("prospects/list", params);
        this.available_prospects = this.$store.getters["prospects/list"];
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          "Oops, something went wrong!"
        );
      }
    },

    selectProspect(item) {
      this.atividade.prospect.id = item.id;
      this.query = item.nome;
    },

    getResponsaveis() {
      this.$store.dispatch("users/list").then(() => {
        this.available_responsaveis = this.$store.getters["users/dropdown"];
        if (this.available_responsaveis && this.available_responsaveis.length) {
          if (this.me)
            this.atividade.responsavel.id = this.me.id;
          else
            this.atividade.responsavel.id = this.available_responsaveis[0].id;
        }
      });
    },

    goBack() {
      this.$router.back();
    },
  },

  mounted() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
  },
};
</script>

<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
</style>
