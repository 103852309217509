<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>category</md-icon>
          </div>
          <h4 class="title">Adicionar Prospect</h4>
        </md-card-header>

        <form @submit.prevent="add">
          <md-card-content>

            <div class="md-layout" v-if="false">
              <div class="md-layout-item md-size-100">
                <div class="md-group">
                  <md-button class="md-warning"><i class="fab fa-whatsapp"></i><md-tooltip md-direction="bottom">Whatsapp</md-tooltip></md-button>
                  <md-button><i class="fab fa-instagram"></i><md-tooltip md-direction="bottom">Instagram</md-tooltip></md-button>
                  <md-button><md-icon>call</md-icon><md-tooltip md-direction="bottom">Telefone</md-tooltip></md-button>
                  <md-button><md-icon>people</md-icon><md-tooltip md-direction="bottom">Indicação</md-tooltip></md-button>
                  <md-button><md-icon>help_outline</md-icon><md-tooltip md-direction="bottom">Outros</md-tooltip></md-button>
                </div>

              </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-10 md-form-label">
                Origem
              </label>
              <div class="md-layout-item">
                <md-radio v-model="prospect.origem" value="1">WhatsApp</md-radio>
                <md-radio v-model="prospect.origem" value="2">Instagram</md-radio>
                <md-radio v-model="prospect.origem" value="3">Telefone</md-radio>
                <md-radio v-model="prospect.origem" value="4">Indicação</md-radio>
                <md-radio v-model="prospect.origem" value="5">Outros</md-radio>
              </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-10 md-form-label">
                Nome
              </label>
              <div class="md-layout-item md-size-50">
                <md-field class="md-invalid">
                  <md-input v-model="prospect.nome" type="text" placeholder="Nome e Sobrenome"></md-input>
                  <validation-error :errors="apiValidationErrors.nome" />
                </md-field>
              </div>
              <label class="md-layout-item md-size-10 md-form-label">
                Responsável
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>Selecione...</label>
                  <md-select v-model="prospect.responsavel.id" name="responsaveis">
                    <md-option
                      v-for="responsavel in available_responsaveis"
                      :key="responsavel.id"
                      :value="responsavel.id"
                    >
                      {{ responsavel.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-10 md-form-label">
                E-mail
              </label>
              <div class="md-layout-item md-size-50">
                <md-field class="md-invalid">
                  <md-input v-model="prospect.email" type="email" placeholder="exemplo@email.com"></md-input>
                </md-field>
              </div>
              <label class="md-layout-item md-size-10 md-form-label">
                Telefone
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="prospect.telefone" v-mask="'(##) #####-####'" type="text" placeholder="(71) 99999-9999"></md-input>
                </md-field>
              </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-10 md-form-label">
                WhatsApp
              </label>
              <div class="md-layout-item md-size-30">
                <md-field class="md-invalid">
                  <md-input v-model="prospect.whatsapp" v-mask="'+55 ## #####-####'" type="text" placeholder="+55 71 99999-9999"></md-input>
                </md-field>
              </div>
              <label class="md-layout-item md-size-10 md-form-label">
                Receita (R$)
              </label>
              <div class="md-layout-item md-size-20">
                <md-field class="md-invalid">
                  <md-input v-model="prospect.receita" type="number" placeholder="99.99"></md-input>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-10 md-form-label">
                Interesse
              </label>
              <div class="md-layout-item">
                  <ckeditor
                    :editor="editor"
                    v-model="prospect.interesse"
                    :config="editorConfig"
                  ></ckeditor>
                  <validation-error :errors="apiValidationErrors.interesse" />
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-10 md-form-label">
                Assuntos
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>Selecione ...</label>
                  <md-select
                    v-model="tags"
                    @md-selected="updateTags"
                    name="tags"
                    multiple
                  >
                    <md-option
                      v-for="tag in available_tags"
                      :key="'tag_' + tag.id"
                      :value="tag.id"
                    >
                      {{ tag.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button @click="goBack" class="md-primary">
              <md-icon>arrow_back</md-icon>
              Voltar
            </md-button>
            <md-button type="submit" class="md-success">
              <md-icon>save</md-icon>
              Adicionar
            </md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Vue from 'vue';
import Vuex from 'vuex';
import { MdField } from 'vue-material/dist/components'

Vue.use(MdField);

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
  methods: {
    isInvalidValue: function isInvalidValue () {
      return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput;
    }
  }
}));

export default {
  components: { ValidationError, ckeditor: CKEditor.component },

  mixins: [formMixin],

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "Outdent",
          "Indent",
          "TextColor",
          "BGColor",
        ],
      },
      prospect: {
        type: 'prospects',
        nome: null,
        email: null,
        telefone: null,
        whatsapp: null,
        receita: 0,
        status: 1,
        origem: 1,
        interesse: null,
        tags: [],
        responsavel: {
          id: null,
          type: "users",
        },
        relationshipNames: ["responsavel", "tags"]
      },
      available_responsaveis: [],
      available_tags: [],
      tags: [],
    };
  },

  computed: {
    ...Vuex.mapState({
      me: state => state.profile.me
    })
  },

  created() {
    this.getResponsaveis();
    this.getTags();
  },

  methods: {
    async add() {
      this.resetApiValidation();

      try {
        await this.$store.dispatch("prospects/add", this.prospect);
        await this.$store.dispatch("alerts/success","Prospect added successfully.");
        this.prospect = this.$store.getters["prospects/prospect"];
        this.goToProspectsAtividades(this.prospect.id);
      } catch (e) {
        if(e.response.data.errors[0]) {
          await this.$store.dispatch("alerts/error", e.response.data.errors[0].title)
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch("alerts/error", "Oops, something went wrong!")
        }
      }
    },

    getResponsaveis() {
      this.$store.dispatch("users/list").then(() => {
        this.available_responsaveis = this.$store.getters["users/dropdown"];
        if (this.available_responsaveis && this.available_responsaveis.length) {
          if (this.me)
            this.prospect.responsavel.id = this.me.id;
          else
            this.prospect.responsavel.id = this.available_responsaveis[0].id;
        }
      });
    },

    updateTags() {
      this.prospect.tags = [];
      this.tags.forEach((tag) => {
        this.prospect.tags.push({
          id: tag,
          type: "tags",
        });
      });
    },

    getTags() {
      this.$store.dispatch("tags/list").then(() => {
        this.available_tags = this.$store.getters["tags/dropdown"];
        if (this.available_tags && this.available_tags.length) {
          this.updateTags();
        }
      });
    },

    goToProspectsAtividades(id) {
      this.$router.push({ name: "List ProspectsAtividades", params: { id } });
    },

    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}
</style>
