<template>
  <li
    :class="[
      { 'timeline-inverted': inverted },
      { 'timeline-simple': !inverted }
    ]"
  >
    <slot name="badge">
      <div class="timeline-badge" :class="badgeType">
        <md-icon>{{ badgeIcon }}</md-icon>
      </div>
    </slot>
    <div class="timeline-panel" style="padding-bottom: 0">
      <div class="timeline-heading">
        <slot name="header"></slot>
      </div>
      <div class="timeline-body" v-if="$slots.content">
        <slot name="content"></slot>
      </div>
      <h6 v-if="$slots.footer" style="border-top: 1px solid #eeeeee">
        <slot name="footer"></slot>
      </h6>
    </div>
  </li>
</template>
<script>
export default {
  name: "time-line-item",
  props: {
    inverted: Boolean,
    badgeType: {
      type: String,
      default: "success"
    },
    badgeIcon: {
      type: String,
      default: ""
    }
  }
};
</script>
<style></style>
