import service from '@/store/services/prospects-service';

const state = {
  list: {},
  prospect: {},
  meta: {},
  url: null,
  analysis: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, prospect) => {
    state.prospect = prospect;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_ANALYSIS: (state, analysis) => {
    state.analysis = analysis;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((prospect) => { commit('SET_RESOURCE', prospect); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((prospect) => { commit('SET_RESOURCE', prospect); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((prospect) => { commit('SET_RESOURCE', prospect); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  analysis({commit, dispatch}, params) {
    return service.analysis(params)
      .then((analysis) => { commit('SET_ANALYSIS', analysis); });
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  prospect: state => state.prospect,
  url: state => state.url,
  analysis: state => state.analysis
};

const prospects = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default prospects;
