<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon">
            <md-icon>group</md-icon>
          </div>
          <p class="category">Prospects</p>
          <h3 class="title">
            +<animated-number :value="prospects"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>update</md-icon>
            No mês atual
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>pending_actions</md-icon>
          </div>
          <p class="category">Tarefas pendentes</p>
          <h3 class="title">
            <animated-number :value="atividades_pendentes"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon class="text-danger">warning</md-icon>
            <a href="#" @click="goToAtividades">Listar tarefas</a>
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>person_add</md-icon>
          </div>
          <p class="category">Novos Clientes</p>
          <h3 class="title">
            +<animated-number :value="prospects_convertidos"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>update</md-icon>
            No mês atual
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>person_add_disabled</md-icon>
          </div>
          <p class="category">Perdidos</p>
          <h3 class="title">
            <animated-number :value="prospects_perdidos"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>update</md-icon>
            No mês atual
          </div>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        header-animation="false"
        :chart-data="prospectsChart.data"
        :chart-options="prospectsChart.options"
        :chart-responsive-options="prospectsChart.responsiveOptions"
        chart-type="Bar"
        chart-inside-header
        background-color="blue"
      >
        <template slot="content">
          <h4 class="title">Prospects</h4>
          <p class="category">
            Número de novos propects por mês
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            Últimos 6 meses
          </div>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        header-animation="false"
        :chart-data="prospectsConvertidosChart.data"
        :chart-options="prospectsConvertidosChart.options"
        chart-type="Bar"
        chart-inside-header
        background-color="green"
      >
        <template slot="content">
          <h4 class="title">Novos Clientes</h4>
          <p class="category">
            <span class="text-success"
              ><i class="fas fa-long-arrow-alt-up"></i>
              5%
            </span>
            de aumento em relação ao mês anterior
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            Últimos 6 meses
          </div>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        header-animation="false"
        :chart-data="prospectsPerdidosChart.data"
        :chart-options="prospectsPerdidosChart.options"
        chart-type="Bar"
        chart-inside-header
        background-color="rose"
      >
        <template slot="content">
          <h4 class="title">Prospects perdidos</h4>
          <p class="category">
            Número de prospects sem possibilidade de conversão
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            Últimos 6 meses
          </div>
        </template>
      </chart-card>
    </div>
    <div class="md-layout-item md-size-100">
      <global-sales-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>pending_actions</md-icon>
          </div>
          <h4 class="title">Top 10 - Atividades atrasadas</h4>
        </template>

        <template slot="content">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-table
                :value="atividades_pendentes_table"
                class="table-striped table-hover"
              >
                <md-table-row slot="md-table-row" slot-scope="{ item }" @click="goToProspectsAtividades(item.prospect_id)">
                  <md-table-cell md-label="Descrição"><span v-html="item.texto"></span></md-table-cell>
                  <md-table-cell md-label="Prospect">{{
                    item.nome
                  }}</md-table-cell>
                  <md-table-cell md-label="Agenda">{{
                    taskDateFormat(item.agenda_at)
                  }}</md-table-cell>
                  <md-table-cell md-label="Atraso (dias)" md-numeric>{{
                    item.dias
                  }}</md-table-cell>
                </md-table-row>
              </md-table>

            </div>
          </div>
        </template>
      </global-sales-card>
    </div>
  </div>
</template>

<script>
import {
  StatsCard,
  ChartCard,
  GlobalSalesCard,
  AnimatedNumber,
} from "@/components";
import moment from "moment";

export default {
  components: {
    StatsCard,
    ChartCard,
    GlobalSalesCard,
    AnimatedNumber,
  },

  data() {
    return {
      prospects: 0,
      atividades_pendentes: 0,
      prospects_convertidos: 0,
      prospects_perdidos: 0,

      // Tables
      atividades_pendentes_table: null,

      // Charts
      prospectsChart: {
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          chartPadding: {
            top: 30,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      prospectsConvertidosChart: {
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          chartPadding: {
            top: 30,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      prospectsPerdidosChart: {
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          chartPadding: {
            top: 30,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      }
    };
  },

  methods: {
    goToAtividades() {
      this.$router.push({ name: "List Atividades" });
    },

    goToProspectsAtividades(id) {
      this.$router.push({ name: "List ProspectsAtividades", params: { id } });
    },

    taskDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('L') : '';
    }
  },

  mounted() {
    this.$store.dispatch("dashboard/get").then(() => {
        this.prospects = this.$store.getters["dashboard/prospects"];
        this.atividades_pendentes = this.$store.getters["dashboard/atividades_pendentes"];
        this.prospects_convertidos = this.$store.getters["dashboard/prospects_convertidos"];
        this.prospects_perdidos = this.$store.getters["dashboard/prospects_perdidos"];

        // Charts
        this.prospectsChart.data = {
          labels: this.$store.getters["dashboard/prospects_chart"].labels,
          series: [this.$store.getters["dashboard/prospects_chart"].series]
        };
        this.prospectsConvertidosChart.data = {
          labels: this.$store.getters["dashboard/prospects_convertidos_chart"].labels,
          series: [this.$store.getters["dashboard/prospects_convertidos_chart"].series]
        };
        this.prospectsPerdidosChart.data = {
          labels: this.$store.getters["dashboard/prospects_perdidos_chart"].labels,
          series: [this.$store.getters["dashboard/prospects_perdidos_chart"].series]
        };

        // Tables
        this.atividades_pendentes_table = this.$store.getters["dashboard/atividades_pendentes_table"];
    });
  }
};
</script>
