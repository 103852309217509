<template>
  <footer class="footer">
    <div class="container">
      <div class="container md-offset">
        <div class="copyright text-center" style="width: 100%;">
          <a href="https://www.ikons.com.br" target="_blank">IKONS Tecnologia</a> &copy; {{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
