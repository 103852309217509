<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-80 mx-auto">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>tune</md-icon>
          </div>
          <h4 class="title">Filtros</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <label class="md-layout-item md-size-5 md-form-label">
              Buscar:
            </label>
            <div class="md-layout-item md-size-55">
              <md-field md-clearable>
                <label>Por nome, telefone ou e-mail</label>
                <md-input type="text" v-model="filter.query"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-datepicker md-immediately
                v-model="filter.createdAtFrom"
                :md-model-type="String"
                :md-open-on-focus="false"
                v-mask="'##/##/####'"
              >
                <label>Periodo De:</label>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-size-20">
              <md-datepicker md-immediately
                v-model="filter.createdAtTo"
                :md-model-type="String"
                :md-open-on-focus="false"
                v-mask="'##/##/####'"
              >
                <label>Periodo Ate:</label>
              </md-datepicker>
            </div>
          </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-5 md-form-label">
                Assunto:
              </label>
              <div class="md-layout-item md-size-55">
                <md-field>
                  <label>Selecione...</label>
                  <md-select
                    v-model="filter.tags"
                    name="tags"
                    multiple
                  >
                    <md-option
                      v-for="tag in available_tags"
                      :key="'tag_' + tag.id"
                      :value="tag.id"
                    >
                      {{ tag.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-80 mx-auto">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="md-group">
            <md-button class="md-sm" :class="getFilterStatusClass(0)" @click="setFilterStatus(0)">Todos</md-button>
            <md-button class="md-sm" :class="getFilterStatusClass(3)" @click="setFilterStatus(3)">Perdido</md-button>
            <md-button class="md-sm" :class="getFilterStatusClass(1)" @click="setFilterStatus(1)">Em andamento</md-button>
            <md-button class="md-sm" :class="getFilterStatusClass(2)" @click="setFilterStatus(2)">Ganho</md-button>
          </div>
        </div>
        <div class="md-layout-item">
          <div class="md-group">
            <md-button class="md-sm" :class="getFilterEtapaClass(0)" @click="setFilterEtapa(0)">Todos</md-button>
            <md-button class="md-sm" :class="getFilterEtapaClass(1)" @click="setFilterEtapa(1)">{{ etapaNome(1) }}</md-button>
            <md-button class="md-sm" :class="getFilterEtapaClass(2)" @click="setFilterEtapa(2)">{{ etapaNome(2) }}</md-button>
            <md-button class="md-sm" :class="getFilterEtapaClass(3)" @click="setFilterEtapa(3)">{{ etapaNome(3) }}</md-button>
            <md-button class="md-sm" :class="getFilterEtapaClass(4)" @click="setFilterEtapa(4)">{{ etapaNome(4) }}</md-button>
          </div>
        </div>
        <div class="md-layout-item md-size-100" style="display: none">
          <div class="text-right">
            <md-button class="md-danger" @click="goToAdd()">
              <md-icon>person_add</md-icon>Novo Prospect
            </md-button>
          </div>
        </div> 
      </div>
 
      <md-card v-for="item in table" :key="item.id">
        <md-card-header md-alignment="space-between">
          <div class="md-layout">
            <div class="md-layout-item md-size-75" style="padding-left: 0 !important">
                <div style="display: grid; grid-template-columns: 60px 300px auto;">
                  <div class="timeline-badge danger" style="grid-row: 1/3;">{{ prospectPrefix(item.nome) }}</div>
                  <div style="display: flex; margin: 12px; font-size: 1.6rem; cursor: pointer; grid-column: 2/4;" @click="goToProspectsAtividades(item.id)">{{ item.nome }}
                    <div class="tags" style="margin-left: 15px; margin-top: -5px;">
                      <span
                        v-for="(tag, index) in item.tags"
                        :key="'tag' + index"
                        class="badge badge-default"
                        :style="{ backgroundColor: tag.color, margin: '.1rem' }"
                      >
                        {{ tag.name }}
                      </span>
                    </div>
                  </div>
                  <div style="opacity: 0.84" class="md-subhead">
                    <div v-if="item.telefone"><md-icon>phone</md-icon>{{ item.telefone }}</div>
                    <div v-if="item.email"><md-icon>email</md-icon>{{ item.email }}</div>
                  </div>
                  <div>
                    <div v-for="alert in analysisHolder[item.id]" :key="alert.mensagem">
                      <span><md-icon :style="analysisStyle(alert.tipo)">warning_amber</md-icon>{{ alert.mensagem }}</span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="md-layout-item md-size-25 ml-auto">
              <div>
                <div style="text-align: center;">
                  <div class="md-group">
                    <md-button class="md-round md-sm" :class="statusClass(3, item.status)" @click="setStatus(item, 3)"><md-icon>close</md-icon>
                      <md-tooltip md-direction="bottom">perdido</md-tooltip>
                    </md-button>
                    <md-button class="md-round md-sm" :class="statusClass(1, item.status)" @click="setStatus(item, 1)"><md-icon>directions_run</md-icon>
                      <md-tooltip md-direction="bottom">em andamento</md-tooltip>
                    </md-button>
                    <md-button class="md-round md-sm" :class="statusClass(2, item.status)" @click="setStatus(item, 2)"><md-icon>emoji_events</md-icon>
                      <md-tooltip md-direction="bottom">ganho</md-tooltip>
                    </md-button>
                  </div>
                </div>
                <div style="padding-top: 15px; text-align: center;">
                  Previsao de receita: <h3 style="margin-top: 0px; margin-bottom: 0px;">{{ item.receita | toCurrency }}</h3>
                </div>
              </div>
            </div>
            <div>
              <span><b>Data cadastro: </b>{{ createdDateFormat(item.createdAt) }}</span>
            </div>
         </div>
        </md-card-header>

        <md-card-actions md-alignment="space-between">
          <div>
            <div class="md-group">
              <md-button class="md-round md-sm" :class="etapaClass(1, item.etapa)" @click="setEtapa(item, 1)"></md-button>
              <md-button class="md-round md-sm" :class="etapaClass(2, item.etapa)" @click="setEtapa(item, 2)"></md-button>
              <md-button class="md-round md-sm" :class="etapaClass(3, item.etapa)" @click="setEtapa(item, 3)"></md-button>
              <md-button class="md-round md-sm" :class="etapaClass(4, item.etapa)" @click="setEtapa(item, 4)"></md-button>
            </div>
            <div>
              <span><b>Etapa: </b>{{ etapaNome(item.etapa) }} (Funil de Vendas)</span>
            </div>
          </div>
          <div>
            <md-button class="md-simple md-primary" @click="goToProspectsAtividades(item.id)">
              <md-icon>timeline</md-icon>Follow-Up
            </md-button>
            <md-button class="md-simple md-success" @click="goToEdit(item.id)">
              <md-icon>edit</md-icon>Editar
            </md-button>
            <md-button class="md-simple md-danger" @click="destroy(item.id)">
              <md-icon>delete</md-icon>Excluir
            </md-button>
          </div>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Exibindo de {{ from + 1 }} - {{ to }} de {{ total }} prospect(s)
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import Pagination from "@/components/Pagination";
import Swal from "sweetalert2";
import NProgress from 'nprogress';
import service from '@/store/services/prospects-service';

export default {
  components: {
    pagination: Pagination,
  },
  data() {
    return {
      responsive: false,
      table: [],
      analysisHolder: [],

      sortation: {
        field: "created_at",
        order: "desc",
      },

      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200],
      },

      total: 0,

      filter: {
        query: null,
        etapa: 0,
        status: 1,
        createdAtTo: null,
        createdAtFrom: null,
        tags: []
      },
      available_tags: [],

      test: [
        { type: 'danger', message: 'Tarefa(s) atrasada(s)' },
        { type: 'warning', message: 'Nenhuma tarefa agendada' },
        { type: 'success', message: 'Tarefa(s) agendada(s)'}
      ],
    };
  },

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    simpleTimeline() {
      if (this.responsive) {
        return "simple";
      }
      return "";
    }
  },

  watch: {
    filter: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },

    sortation: {
      handler: "getList",
      immediate: false,
      deep: true,
    },

    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  filters: {
    toCurrency(value) {
      if (typeof value !== "number") {
          return value;
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
      });
      return formatter.format(value);
    }
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    getList() {
      NProgress.start();
      NProgress.set(0.4);
      let createdAtBetween = new Array();
      if (this.filter.createdAtFrom)
        createdAtBetween.push(moment(this.filter.createdAtFrom, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00"));
      if (this.filter.createdAtTo)
        createdAtBetween.push(moment(this.filter.createdAtTo, "DD/MM/YYYY").format("YYYY-MM-DD 23:59:59"));

      let params = {
        include: "user,responsavel,tags",
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.filter.query ? { search: this.filter.query } : {}),
          ...(this.filter.tags.length > 0 ? { tags: this.filter.tags } : {}),
          ...(parseInt(this.filter.etapa) > 0 ? { etapa: this.filter.etapa } : {}),
          ...(parseInt(this.filter.status) > 0 ? { status: this.filter.status } : {}),
          ...(createdAtBetween.length == 2 ? { created_at: createdAtBetween } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      this.$store.dispatch("prospects/list", params).then(() => {
        this.table = this.$store.getters["prospects/list"];
        this.total = this.$store.getters["prospects/listTotal"];

        this.table.forEach(item => {
          this.getAnalysis(item.id);
        });

        NProgress.done();
      });
    },

    getAnalysis(id) {
      service.analysis(id).then((analysis) => {
        this.$set(this.analysisHolder, id, analysis);
      });
    },

    prospectPrefix(name) {
      if (name) {
        let words = name.split(" ");
        return words.map(word => word.substring(0, 1)).join("").substring(0, 2).toUpperCase();
      }
      return false;
    },

    async setEtapa(prospect, etapa) {
      try {
        prospect.etapa = etapa;
        await this.$store.dispatch("prospects/update", prospect);
        await this.$store.dispatch(
          "alerts/success",
          "Prospect updated successfully."
        );
        await this.getList();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
        } else {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, something went wrong!"
          );
        }
      }
    },

    async setStatus(prospect, status) {
      try {
        prospect.status = status;
        await this.$store.dispatch("prospects/update", prospect);
        await this.$store.dispatch(
          "alerts/success",
          "Prospect updated successfully."
        );
        await this.getList();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
        } else {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, something went wrong!"
          );
        }
      }
    },

    getTags() {
      this.$store.dispatch("tags/list").then(() => {
        this.available_tags = this.$store.getters["tags/dropdown"];
      });
    },

    async destroy(id) {
      const confirmation = await Swal.fire({
        title: "Excluir este Prospect?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, remova-o!",
        cancelButtonText: "Não, mantenha-o",
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
      });

      if (confirmation.value === true) {
        try {
          await this.$store.dispatch("prospects/destroy", id);
          await this.$store.dispatch(
            "alerts/success",
            "Prospect deleted successfully."
          );
          await this.getList();
        } catch (e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              "alerts/error",
              e.response.data.errors[0].title
            );
          } else {
            await this.$store.dispatch(
              "alerts/error",
              "Oops, something went wrong!"
            );
          }
        }
      }
    },

    goToAdd() {
      this.$router.push({ name: "Add Prospect" });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Prospect", params: { id } });
    },

    customSort() {
      return false;
    },

    analysisStyle(status) {
      switch (status) {
        case 'danger':
          return 'color: red'
        case 'warning':
          return 'color: orange'
        case 'success':
          return 'color: green'
        default:
          return ''
      }
    },

    etapaNome(etapa) {
      switch (etapa) {
        case 1:
          return 'Contato'
        case 2:
          return 'Envio de proposta'
        case 3:
          return 'Follow-up'
        case 4:
          return 'Fechamento'
        default:
          return ''
      }
    },

    etapaClass(etapa, selected) {
      switch (etapa) {
        case 1:
          return 'fv-on'
        case 2:
          return selected >= 2 ? 'fv-on' : 'fv-off'
        case 3:
          return selected >= 3 ? 'fv-on' : 'fv-off'
        case 4:
          return selected >= 4 ? 'fv-on' : 'fv-off'
        default:
          return ''
      }
    },

    getFilterEtapaClass(etapa) {
      if (this.filter.etapa == etapa) {
        return "md-info";
      }
      return "";
    },

    setFilterEtapa(etapa) {
      this.filter.etapa = etapa;
    },

    statusClass(status, selected) {
      switch (status) {
        case 1:
          return selected == 1 ? 'md-warning' : ''
        case 2:
          return selected == 2 ? 'md-success' : ''
        case 3:
          return selected == 3 ? 'md-danger' : ''
        default:
          return ''
      }
    },

    getFilterStatusClass(status) {
      if (this.filter.status == status) {
        return "md-info";
      }
      return "";
    },

    setFilterStatus(status) {
      this.filter.status = status;
    },

    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },

    goToProspectsAtividades(id) {
      this.$router.push({ name: "List ProspectsAtividades", params: { id } });
    },

    exibirFormularioCadastro() {
      this.exibir_formulario_cadastro = !this.exibir_formulario_cadastro;
    },

    createdDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('LL') : '';
    }
  },

  created() {
    this.getTags();
    if (localStorage.prospectsFilters) {
      this.filter = JSON.parse(localStorage.prospectsFilters);
    }
  },

  mounted() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },

  beforeDestroy() {
    localStorage.prospectsFilters = JSON.stringify(this.filter);
    window.removeEventListener("resize", this.onResponsiveInverted);
  }
};
</script>
<style lang="scss">
.timeline-badge {
  color: #FFFFFF;
  width: 50px;
  height: 50px;
  line-height: 51px;
  font-size: 1.4em;
  text-align: center;
  float: left;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.timeline-badge.danger {
  background-color: #f44336;
  -webkit-box-shadow: 0 12px 20px -10px rgb(244 67 54 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(244 67 54 / 20%);
  box-shadow: 0 12px 20px -10px rgb(244 67 54 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(244 67 54 / 20%);
}
.badge {
  font-size: 15px;
}
.md-card-header .md-button+.md-button {
  margin-left: 0px;
}
.fv-on {
  width: 60px !important;
  background-color: #4caf50 !important;
}
.fv-off {
  width: 60px !important;
  background-color: #e0e0e0 !important;
}
.fv-on:focus, .fv-off:focus {
  background-color: #4caf50 !important;
}
.fv-on:hover, .fv-off:hover {
  background-color: #4caf50 !important;
}
</style>
