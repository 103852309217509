<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-80 mx-auto">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <label class="md-layout-item md-size-5 md-form-label">
              Filtros:
            </label>
            <div class="md-layout-item md-size-20">
              <md-datepicker md-immediately>
                <label>Periodo De:</label>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-size-20">
              <md-datepicker md-immediately>
                <label>Periodo Ate:</label>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-size-55">
              <md-field>
                <label>Pesquisar...</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-info">Filtrar</md-button>
        </md-card-actions>
      </md-card>
    </div>

    <div class="md-layout-item md-size-80 mx-auto">
      <div class="md-layout" style="margin-bottom: 50px;">
        <div class="md-layout-item md-xsmall-size-100">
          <div class="md-group">
            <md-button class="md-info">Todas</md-button>
            <md-button>Semana</md-button>
            <md-button>Hoje</md-button>
            <md-button>Definir</md-button>
          </div>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <div class="md-group">
            <md-button class="md-info">Pendentes</md-button>
            <md-button>Finalizadas</md-button>
          </div>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <div class="text-right">
            <md-button class="md-danger" @click="goToAdd()">
              <md-icon>add_circle</md-icon>NOVA TAREFA
            </md-button>
          </div>
        </div> 
      </div>
 
      <md-card v-for="item in table" :key="item.id">
        <md-card-header md-alignment="space-between">
          <div class="md-layout">
            <div class="md-layout-item md-size-30" style="padding-left: 0 !important">
              <badge :type="badgeType(item.tipo)">{{ badgeName(item.tipo) }}</badge>
              <div class="md-subhead" style="font-size: 12px;">{{ registerDateFormat(item.createdAt) }}</div>
            </div>
            <div class="md-layout-item md-size-30 ml-auto" style="text-transform: uppercase; text-align: right">
              <md-icon>person</md-icon>
              <a href="#" style="color: black !important;" @click="goToProspectsAtividades(item.prospect.id)">{{ item.prospect.nome }}</a>
              <div style="text-align: right; text-transform: capitalize; opacity: 0.54;">
                <md-icon>support_agent</md-icon>
                {{ item.responsavel.name }}
              </div> 
            </div> 
          </div>
        </md-card-header>

        <md-card-content>
          <span v-html="item.texto"></span>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div>
            <div v-if="item.agenda_at">
              <md-icon>event</md-icon>
              {{ taskDateFormat(item.agenda_at) }}
            </div>
          </div>
          <div>
            <md-button class="md-success md-simple">
              <md-icon>done</md-icon>Finalizar
            </md-button>
            <md-button class="md-simple" @click="goToEdit(item.id)">
              <md-icon>edit</md-icon>Editar
            </md-button>
            <md-button class="md-danger md-simple" @click="destroy(item.id)">
              <md-icon>delete</md-icon>Excluir
            </md-button>
          </div>
        </md-card-actions>
      </md-card>
      <md-card>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Exibindo de {{ from + 1 }} - {{ to }} de {{ total }} tarefa(s)
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Pagination from "@/components/Pagination";
import Swal from "sweetalert2";
import { Badge } from "@/components";
import moment from "moment";

export default {
  components: {
    pagination: Pagination,
    Badge
  },
  data() {
    return {
      responsive: false,
      table: [],

      query: null,

      sortation: {
        field: "created_at",
        order: "desc",
      },

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
    };
  },

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    simpleTimeline() {
      if (this.responsive) {
        return "simple";
      }
      return "";
    }
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    sortation: {
      handler: "getList",
      immediate: false,
      deep: true,
    },

    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    getList() {
      let params = {
        include: "user,responsavel,prospect",
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { name: this.query } : {}),
          ...(this.query ? { user: this.query } : {}),
          ...(this.query ? { prospect: this.query } : {}),
          ...({ tarefas: null }),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      this.$store.dispatch("atividades/list", params).then(() => {
        this.table = this.$store.getters["atividades/list"];
        this.total = this.$store.getters["atividades/listTotal"];
      });
    },

    async destroy(id) {
      const confirmation = await Swal.fire({
        title: "Delete this atividade?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
      });

      if (confirmation.value === true) {
        try {
          await this.$store.dispatch("atividades/destroy", id);
          await this.$store.dispatch(
            "alerts/success",
            "Atividade deleted successfully."
          );
          await this.getList();
        } catch (e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              "alerts/error",
              e.response.data.errors[0].title
            );
          } else {
            await this.$store.dispatch(
              "alerts/error",
              "Oops, something went wrong!"
            );
          }
        }
      }
    },

    goToAdd() {
      this.$router.push({ name: "Add Atividade" });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Atividade", params: { id } });
    },

    goToProspectsAtividades(id) {
      this.$router.push({ name: "List ProspectsAtividades", params: { id } });
    },

    customSort() {
      return false;
    },

    badgeName(tipo) {
      switch (tipo) {
        case 1:
          return 'Nota'
        case 2:
          return 'Ligacao'
        case 3:
          return 'Tarefa'
        case 4:
          return 'Proposta'
        default:
          return 'Nota'
      }
    },

    badgeType(tipo) {
      switch (tipo) {
        case 1:
          return 'info'
        case 2:
          return 'success'
        case 3:
          return 'danger'
        case 4:
          return 'warning'
        default:
          return 'info'
      }
    },

    badgeIcon(tipo) {
      switch (tipo) {
        case 1:
          return 'description'
        case 2:
          return 'call'
        case 3:
          return 'event'
        case 4:
          return 'request_page'
        default:
          return 'description'
      }
    },

    taskDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().format('LL') : '';
    },

    registerDateFormat(date) {
      var br = moment(date).locale('pt-BR');
      return date ? br.utc().fromNow() : '';
    },

    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  },

  mounted() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  }
};
</script>
<style lang="scss">
.badge {
  font-size: 15px;
}
</style>
