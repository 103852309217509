import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// CRM Atividades
import ListAtividades from "@/pages/CRM/Atividades/ListAtividades.vue";
import AddAtividade from "@/pages/CRM/Atividades/AddAtividade.vue";
import EditAtividade from "@/pages/CRM/Atividades/EditAtividade.vue";
import ListTarefas from "@/pages/CRM/Atividades/ListTarefas.vue";
// CRM Prospects
import ListProspects from "@/pages/CRM/Prospects/ListProspects.vue";
import AddProspect from "@/pages/CRM/Prospects/AddProspect.vue";
import EditProspect from "@/pages/CRM/Prospects/EditProspect.vue";
import ListProspectsAtividades from "@/pages/CRM/Prospects/ListAtividades.vue";
// CRM UserProfile
import UserProfile from "@/pages/CRM/Admin/UserProfile.vue";
// CRM Role Management
import ListRolePage from "@/pages/CRM/Admin/RoleManagement/ListRolePage.vue";
import AddRolePage from "@/pages/CRM/Admin/RoleManagement/AddRolePage.vue";
import EditRolePage from "@/pages/CRM/Admin/RoleManagement/EditRolePage.vue";
// CRM User Management
import ListUserPage from "@/pages/CRM/Admin/UserManagement/ListUserPage.vue";
import AddUserPage from "@/pages/CRM/Admin/UserManagement/AddUserPage.vue";
import EditUserPage from "@/pages/CRM/Admin/UserManagement/EditUserPage.vue";
// CRM Tag Management
import ListTagPage from "@/pages/CRM/Admin/TagManagement/ListTagPage.vue";
import AddTagPage from "@/pages/CRM/Admin/TagManagement/AddTagPage.vue";
import EditTagPage from "@/pages/CRM/Admin/TagManagement/EditTagPage";
// CRM Reposts
import AtividadesReportsPage from "@/pages/CRM/Reports/AtividadesReports.vue";
import ProspectsReportsPage from "@/pages/CRM/Reports/ProspectsReports.vue";

// Category Management
import ListCategoryPage from "@/pages/Dashboard/Examples/CategoryManagement/ListCategoryPage.vue";
import AddCategoryPage from "@/pages/Dashboard/Examples/CategoryManagement/AddCategoryPage.vue";
import EditCategoryPage from "@/pages/Dashboard/Examples/CategoryManagement/EditCategoryPage.vue";
// Item Management
import ListItemPage from "@/pages/Dashboard/Examples/ItemManagement/ListItemPage.vue";
import AddItemPage from "@/pages/Dashboard/Examples/ItemManagement/AddItemPage.vue";
import EditItemPage from "@/pages/Dashboard/Examples/ItemManagement/EditItemPage";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
      meta: { middleware: auth }
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
      meta: { middleware: auth }
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
      meta: { middleware: auth }
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
      meta: { middleware: auth }
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
      meta: { middleware: auth }
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
      meta: { middleware: auth }
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
      meta: { middleware: auth }
    }
  ]
};

let examplesMenu = {
  path: "/examples",
  component: DashboardLayout,
  name: "Examples",
  children: [
    {
      path: "category-management/list-categories",
      name: "List Categories",
      components: { default: ListCategoryPage },
      meta: { middleware: auth }
    },
    {
      path: "category-management/add-category",
      name: "Add Category",
      components: { default: AddCategoryPage },
      meta: { middleware: auth }
    },
    {
      path: "category-management/edit-category/:id",
      name: "Edit Category",
      components: { default: EditCategoryPage },
      meta: { middleware: auth }
    },
    {
      path: "item-management/list-items",
      name: "List Items",
      components: { default: ListItemPage },
      meta: { middleware: auth }
    },
    {
      path: "item-management/add-item",
      name: "Add Item",
      components: { default: AddItemPage },
      meta: { middleware: auth }
    },
    {
      path: "item-management/edit-item/:id",
      name: "Edit Item",
      components: { default: EditItemPage },
      meta: { middleware: auth }
    },
  ]
};


let adminMenu = {
  path: "/admin",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: auth }
    },
    {
      path: "role-management/list-roles",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: { middleware: auth }
    },
    {
      path: "role-management/add-role",
      name: "Add Role",
      components: { default: AddRolePage },
      meta: { middleware: auth }
    },
    {
      path: "role-management/edit-role/:id",
      name: "Edit Role",
      components: { default: EditRolePage },
      meta: { middleware: auth }
    },
    {
      path: "user-management/list-users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: auth }
    },
    {
      path: "user-management/add-user",
      name: "Add User",
      components: { default: AddUserPage },
      meta: { middleware: auth }
    },
    {
      path: "user-management/edit-user/:id",
      name: "Edit User",
      components: { default: EditUserPage },
      meta: { middleware: auth }
    },
    {
      path: "tag-management/list-tags",
      name: "List Tags",
      components: { default: ListTagPage },
      meta: { middleware: auth }
    },
    {
      path: "tag-management/add-tag",
      name: "Add Tag",
      components: { default: AddTagPage },
      meta: { middleware: auth }
    },
    {
      path: "tag-management/edit-tag/:id",
      name: "Edit Tag",
      components: { default: EditTagPage },
      meta: { middleware: auth }
    },
  ]
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
      meta: { middleware: auth }
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
      meta: { middleware: auth }
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
      meta: { middleware: auth }
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
      meta: { middleware: auth }
    }
  ]
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
      meta: { middleware: auth }
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
      meta: { middleware: auth }
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables },
      meta: { middleware: auth }
    }
  ]
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
      meta: { middleware: auth }
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
        middleware: auth
      },
      components: { default: FullScreenMap }
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
      meta: { middleware: auth }
    }
  ]
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User },
      meta: { middleware: auth }
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
      meta: { middleware: auth }
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
        middleware: auth
      },
      components: { default: RtlSupport }
    }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest }
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock
    }
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  //componentsMenu,
  //examplesMenu,
  //formsMenu,
  //tablesMenu,
  //mapsMenu,
  //pagesMenu,
  authPages,
  adminMenu,
  {
    path: "/",
    component: DashboardLayout,
    meta: { middleware: auth },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: { middleware: auth }
      },
      {
        path: "atividades",
        name: "List Atividades",
        components: { default: ListAtividades },
        meta: { middleware: auth }
      },
      {
        path: "atividades/add",
        name: "Add Atividade",
        components: { default: AddAtividade },
        meta: { middleware: auth }
      },
      {
        path: "atividades/edit/:id",
        name: "Edit Atividade",
        components: { default: EditAtividade },
        meta: { middleware: auth }
      },
      {
        path: "tarefas",
        name: "List Tarefas",
        components: { default: ListTarefas },
        meta: { middleware: auth }
      },
      {
        path: "prospects",
        name: "List Prospects",
        components: { default: ListProspects },
        meta: { middleware: auth }
      },
      {
        path: "prospects/add",
        name: "Add Prospect",
        components: { default: AddProspect },
        meta: { middleware: auth }
      },
      {
        path: "prospects/edit/:id",
        name: "Edit Prospect",
        components: { default: EditProspect },
        meta: { middleware: auth }
      },
      {
        path: "prospects/:id/atividades",
        name: "List ProspectsAtividades",
        components: { default: ListProspectsAtividades },
        meta: { middleware: auth }
      },
      {
        path: "reports/atividades",
        name: "Atividades Reports",
        components: { default: AtividadesReportsPage },
        meta: { middleware: auth }
      },
      {
        path: "reports/prospects",
        name: "Prospects Reports",
        components: { default: ProspectsReportsPage },
        meta: { middleware: auth }
      }
    ]
  }
];

export default routes;
