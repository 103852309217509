import service from '@/store/services/dashboard-service';

const state = {
  dashboard: {},
};

const mutations = {
  SET_RESOURCE: (state, dashboard) => {
    state.dashboard = dashboard;
  }
};

const actions = {
  get({commit, dispatch}, params) {
    return service.get(params)
      .then((dashboard) => {
        commit('SET_RESOURCE', dashboard.list.data[0]);
      });
  },
};

const getters = {
  prospects: state => state.dashboard.prospects,
  atividades_pendentes: state => state.dashboard.atividades_pendentes,
  prospects_convertidos: state => state.dashboard.prospects_convertidos,
  prospects_perdidos: state => state.dashboard.prospects_perdidos,
  prospects_chart: state => state.dashboard.prospects_chart,
  prospects_convertidos_chart: state => state.dashboard.prospects_convertidos_chart,
  prospects_perdidos_chart: state => state.dashboard.prospects_perdidos_chart,
  atividades_pendentes_table: state => state.dashboard.atividades_pendentes_table
};

const dashboard = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default dashboard;
