var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('h5',{staticClass:"info-text"},[_vm._v("Are you living in a nice area?")])]),_c('div',{staticClass:"md-layout-item md-size-70 md-small-size-100"},[_c('ValidationProvider',{attrs:{"name":"streetName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Street Name")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.streetName),callback:function ($$v) {_vm.streetName=$$v},expression:"streetName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-size-30 md-small-size-100"},[_c('ValidationProvider',{attrs:{"name":"streetNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Street Number")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.streetNumber),callback:function ($$v) {_vm.streetNumber=$$v},expression:"streetNumber"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('ValidationProvider',{attrs:{"name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("City")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-field',[_c('label',{attrs:{"for":"select"}},[_vm._v("Single Select")]),_c('md-select',{attrs:{"name":"select"},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},[_c('md-option',{attrs:{"value":"angola"}},[_vm._v("Angola")]),_c('md-option',{attrs:{"value":"france"}},[_vm._v("France")]),_c('md-option',{attrs:{"value":"germany"}},[_vm._v("Germany")]),_c('md-option',{attrs:{"value":"england"}},[_vm._v("England")])],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }