import service from '@/store/services/reports-service';

const state = {
  atividades: {},
  prospects: {},
  meta: {},
};

const mutations = {
  SET_ATIVIDADES: (state, atividades) => {
    state.atividades = atividades;
  },
  SET_PROSPECTS: (state, prospects) => {
    state.prospects = prospects;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  atividades({commit, dispatch}, params) {
    return service.atividades(params)
      .then(({atividades, meta}) => {
        commit('SET_ATIVIDADES', atividades);
        commit('SET_META', meta);
      });
  },

  prospects({commit, dispatch}, params) {
    return service.prospects(params)
      .then(({prospects, meta}) => {
        commit('SET_PROSPECTS', prospects);
        commit('SET_META', meta);
      });
  },
};

const getters = {
  listAtividades: state => state.atividades,
  listAtividadesTotal: state => state.meta.page.total,
  listProspects: state => state.prospects,
  listProspectsTotal: state => state.meta.page.total,
};

const reports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default reports;
