<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <li class="button-container" style="margin-right: 10px">
          <div class="">
            <md-button
              class="md-default md-danger" @click="goToAdd()"
              ><md-icon>person_add</md-icon>Novo Prospect
            </md-button>
          </div>
        </li>

        <li class="button-container" style="margin-right: 10px" v-if="false">
          <div class="">
            <md-button
              class="md-default md-block"
              :href="documentationLink"
              target="_blank"
              >Documentation
            </md-button>
          </div>
        </li>

        <div class="md-collapse">
          <div class="md-autocomplete">
            <md-autocomplete
              class="search"
              style="width: 350px"
              v-model="query"
              :md-options="prospects"
              @md-selected="selectProspect"
              :md-open-on-focus="false"
            >
              <label>Buscar Prospect...</label>
              <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item.nome }}</template>
            </md-autocomplete>
          </div>
          <md-list>
            <md-list-item @click="goToDashboard()">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item>

            <li class="md-list-item">
              <a
                @click="goToNotifications"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                  </drop-down>
                </div>
              </a>
            </li>

            <md-list-item @click="goToProfile">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      query: "",
      prospects: [
      ],
      documentationLink:
        "https://vue-material-dashboard-laravel-pro.creative-tim.com/documentation/",
      downloadUrl:
        "https://www.creative-tim.com/product/vue-material-dashboard-laravel-pro",
    };
  },

  watch: {
    query: {
      handler: "searchProspectDebounced",
      immediate: true,
    },
  },

  methods: {
    searchProspectDebounced: _.debounce(function () {
      this.searchProspect();
    }, 300),

    async searchProspect() {
      try {
        if (!this.query || this.query == "" || this.query.length < 3)
          return;

        let params = {
          include: "responsavel",
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
        };

        await this.$store.dispatch("prospects/list", params);
        this.prospects = this.$store.getters["prospects/list"];
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          "Oops, something went wrong!"
        );
      }
    },

    selectProspect(item) {
      this.query = "";
      this.$router.push({ name: "List ProspectsAtividades", params: { id: item.id } });
    },

    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },

    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },

    goToNotifications() {
      //this.$router.push({ name: "Notifications" });
    },

    goToUsers() {
      this.$router.push({ name: "User Page" });
    },

    goToProfile() {
      this.$router.push({name: "User Profile"})
    },

    goToAdd() {
      this.$router.push({ name: "Add Prospect" });
    },
  },
};
</script>
