<template>
  <div class="md-layout text-center login-fix-page">

    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100 login-page-fix"
    >
      <form @submit.prevent="login">
        <login-card header-color="warning">
          <div slot="title" class="title" style="
            background-image: url(/img/winner-logo.png);
            background-repeat: no-repeat;
            background-position: center;
            height: 99px;
            margin-bottom: -40px;"></div>
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>assignment_ind</md-icon>
            <label>Usuário...</label>
            <md-input v-model="username" type="username" />
            <validation-error :errors="apiValidationErrors.username" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Senha...</label>
            <md-input v-model="password" type="password" />
          </md-field>
          <md-button
            slot="footer"
            class="md-simple md-lg"
            type="submit"
          >
            Entrar
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";

export default {
  components: {
    LoginCard,
    ValidationError,
  },
  mixins: [formMixin],
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  data: () => ({
    username: "",
    password: "",
  }),
  methods: {
    async login() {
      const user = {
        data: {
          type: "token",
          attributes: {
            username: this.username,
            password: this.password,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        await this.$store.dispatch("login", { user, requestOptions });
      } catch (e) {
        await this.$store.dispatch("alerts/error", "Invalid credentials!");
        this.setApiValidation(e.response.data.errors);
      }
    },
  },
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>
<style>
.md-title {
  display: none !important;
}
</style>
