import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function get() {
  const options = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  };

  return axios.get(`${url}/dashboard`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

export default {
  get
};
